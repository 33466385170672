.activeBtn {
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
}

.edit-icon {
  /* color: #5686e1 !important; */
  color: #ff0000bf;
  cursor: pointer;
}

.addBtn {
  /* background-color: #00e785 !important; */
  color: black !important;
  display: flex !important;
  float: inline-end !important;
  margin: 8px 0px !important;
  border: none !important;
  outline: none !important;
  padding: 12px;
  padding: 8px 16px;
  font-weight: 600;
}

.disableBtn {
  background-color: grey !important;
}

.approvebtn {
  background-color: #00e785 !important;
  color: black !important;
  display: flex !important;
  justify-content: center;
  margin: auto !important;
  border: none !important;
  outline: none !important;
  padding: 12px;
  padding: 8px 16px;
  font-weight: 600;
}

.uploadBtn {
  background-color: #00e785;
  padding: 0px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  outline: none;
}
.submitBtn {
  background-color: #00e785 !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  /* width: 80px; */
  padding: 8px 20px !important;
  border-radius: 10px;
  min-width: 0px !important;
}

.contentCenter {
  display: flex !important;
  justify-content: center !important;
}

.contentEnd {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.card {
  box-shadow: 0px 5px 15px 0px #00000040 !important;
  border-radius: 10px !important;
  height: 150px !important;
  padding: 5px !important;
  width: 30% !important;
  margin: 10px !important;
}

.uppercontent {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px 16px !important;
}

.cardText {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #000000;
  /* text-align: center; */
  margin: 4px;
  padding: 0px;
  align-self: center;
}

.cardcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}
.cancelBtn {
  border: solid 1px #ff3939;
  border-radius: 10px;
  padding: 10px 10px;
  background-color: white;
  color: black;
  outline: none !important;
  margin-right: 20px;
}
.cancelBtn:hover {
  background-color: #ff3939;
  border: solid 1px #ff3939;
}

.paymentcardcontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  padding: 10px 30px;
  border-radius: 18px;
  font-weight: 600 !important;
  /* width: 250px; */
  max-width: 300px;
  margin: 20px;
}

.paymenttexthead {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentextcontent {
  /* height: 70; */
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymenttextcontainer {
  /* padding-top: 2rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 18px;
  align-items: center;
  width: 250px;
  padding: 10px 20px;
  background-color: white;
  margin: 20px !important;

  /* height: 60vh; */
}

.paymentcardpricing {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid;
  border-image: linear-gradient(
      90deg,
      rgba(60, 74, 83, 0.9),
      rgba(60, 74, 83, 0)
    )
    1;
  border-left: none;
  border-top: none;
  border-right: none;
  /* border-bottom: 2px blue solid;
  border-radius: 32px; */
}
.phone_number_field {
  width: 50% !important;
}
.phone_number_field > input {
  padding: 8px;
  border: 1px rgba(128, 128, 128, 0.384) solid;
  border-radius: 3px;
}

.phone_number_field > input:focus-visible {
  border-color: #00e785 !important;
}

.paymentcardpricing p:nth-child(1) {
  font-size: 28px;
  margin: 0;
}
.paymentcardpricing p:nth-child(2) {
  font-size: 18px;
  color: rgb(124, 116, 116);
  margin: 0;
}

.lastpaymentcard {
  font-size: 16px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  height: 20%;
}

.paymentcardchecklist {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 10px;
  /* height: 60%; */
}

.paymentbutton {
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  background: #00e785;
  margin: 20px 0px;
}

::-webkit-scrollbar {
  width: 5px !important; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(196 205 213); /* Set the color of the scrollbar thumb */
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.4375em !important;
}

.css-1x5jdmq {
  height: 0.4375em !important;
}

.pagination-text {
  margin: 0px !important;
  padding: 0px !important;
}

.MuiTablePagination-selectLabel {
  padding-top: 10px !important;
}

.MuiTablePagination-displayedRows {
  padding-top: 12px !important;
}

/* .pagination-text .MuiTablePagination-selectLabel .MuiTablePagination-displayedRows{
  padding-top: 10px !important;
} */

.pagination-text:first-child {
  padding: 0px !important;
}

.tablecontainer {
  max-width: 100px !important;
  min-width: 30px !important;
  word-wrap: break-word;
  /* overflow: hidden; */
}

.tablecontainer:hover {
  overflow: visible;
  word-wrap: break-word;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border: 1px solid green;
  padding: 3px;
  color: green;
  top: 20px;
  left: 20px;
}

.skeleton {
  background: #e1e1e1;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

.planbutton {
  border-radius: 5px;
  text-align: center;
  color: #040027;
  font-weight: 550;
  background-color: #04002740;
  /* max-width: 50%; */
  padding: 5px;
  margin: auto;
}

.viewtext {
  /* color:#67c5f5; */

  cursor: pointer;
  font-weight: 550;
  border-radius: 5px;
  border: #00e785 2px solid;
  padding: 5px 0;
}

.viewtext:nth-child(odd) {
  color: grey;
}
.viewtext:nth-child(even) {
  color: rgb(151, 29, 29);
}

.viewtext:hover {
  background-color: #00e785;
  color: #040027;
}

.active-dot {
  color: gray;
}

.non-active-dot {
  color: inherit;
}

.uploadbtn:hover {
  cursor: pointer !important;
}

.active_card {
  pointer-events: none;
  background-color: #000000;
  color: white;
  border: 1px solid #00e68a;
}
.update_card {
  pointer-events: initial;
  background-color: white;
}
