.barContainer {
  width: 100%;
  border-radius: 10px;
  border: Mixed solid #e5e5ef;
  background-color: white;
  /* need to give as common */
  -webkit-box-shadow: -2px -2px 20px 3px rgba(184, 184, 184, 1);
  -moz-box-shadow: -2px -2px 20px 3px rgba(184, 184, 184, 1);
  box-shadow: -2px -2px 20px 3px rgba(184, 184, 184, 1);
  padding: 10px;
  overflow: auto;
  height: 100%;
}
.responsechart {
  width: 35%;
  height: 100%;
}

.statistics {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap !important;
  gap: 20px;
}

.dashboardcontainer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-direction: column;
  gap: 30px;
}

.barheader:nth-child(1) {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #9291a5;
  margin-bottom: 10px;
}
.barheader:nth-child(2) {
  margin-top: 0;
  color: #1e1b39;
  font-size: 22px;
  font-weight: 600;
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .card {
    width: 100% !important;
  }
  .barContainer {
    width: 100%;
    height: 31vh;
  }
  .responsechart {
    width: 100%;
    height: 50vh;
  }
  .statistics {
    height: max-content !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1100px) {
  .card {
    width: 42% !important;
    height: 20vh !important;
  }

  .uppercontent {
    gap: 15px !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1000px) {
  .barContainer {
    width: 100%;
  }
  .responsechart {
    width: 100%;
  }
}
