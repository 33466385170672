.abouts-view{
    overflow-wrap: break-word;
    height: 40vh;
    position: relative;

}

.gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px; /* Adjust as needed */
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
    pointer-events: none;
  }