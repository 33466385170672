.swichlayout{
    max-width: 280px;
    width: 500px;
    /* height: 70px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:white;
    border-radius: 28px;
    text-align: center;
  }

  .switchchild{
    cursor: pointer;
    width: 100%;
    /* height: 100%; */
    padding: 10px;
    align-self: center;
    border-radius: 20px;
    margin: 5px;
    transition: background-color 500ms;
  }

  .paymentcontainer{
    background-color: #030028;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }